let listener = {
   data () {
      return {
        askToken: null,
        timer1s: null,
        counter: 0,
        lastTimeGotData: null,
        requestPoints: [],
      }
   },
   sockets: {
      connect() {
         let self = this
         setTimeout(function(){
            self.registerAsListener()
         }, 200)
      },
      getLastValuePointsReply: function(data) {
      //   console.log("on socket getLastValuePointsReply")
      //   console.log(data)
        this.lastTimeGotData = new Date()
        let forceApply = new Date().getSeconds() % 5 == 0;
        if(data && data.content && data.content.length>0 && data.askToken && data.askToken==this.askToken){
            if(this.widgets){
               this.widgets.forEach(widget => {
                  this.applyDataToDisplayPoint(widget, data, forceApply)
               })
            }
            this.$forceUpdate()
        }
      },
      reportLastValue: function (data) {
         if(this.$root.isRemote && data && data.devCode == this.$root.devCode && data.content && data.content.length>0){
            // console.log('reportLastValue')
            // console.log(data)
            if(this.widgets){
               this.widgets.forEach(widget => {
                  this.applyDataToDisplayPoint(widget, data, true)
               })
            }
            this.$forceUpdate()
        }
      }
   },
   methods: {
      applyDataToDisplayPoint (displayPoint, data, forceApply) {
         let matchDataPoint = data.content.find(p => p.reportName && p.reportName == displayPoint.reportName)
         if(matchDataPoint)
         {
            let rawValue = matchDataPoint.value
            if(matchDataPoint.reportName.startsWith('accu') && matchDataPoint.accuDateValue!=undefined) {
               rawValue = matchDataPoint.accuDateValue
            }
            // console.log(matchDataPoint.reportName)
            // console.log(rawValue)
            let newValue = Math.round(rawValue*100)/100
            if (newValue>1) {
               newValue = Math.round(rawValue*10)/10
            }
            if(forceApply 
               || !displayPoint.displayDelta
               || Math.abs(Number(rawValue)-newValue)>=displayPoint.displayDelta) {
               displayPoint.value = newValue
               displayPoint.qos = matchDataPoint.qos
               displayPoint.isOnline = new Date().getTime() - new Date(matchDataPoint.recordDate).getTime() < 60 * 1000
            }
            // console.log(displayPoint)
         }

         if(displayPoint.sourceDisplay) {
            let matchDisplayPoint = data.content.find(p => p.reportName && p.reportName == displayPoint.sourceDisplay)
            // console.log(matchDisplayPoint)
            if(matchDisplayPoint) {
               displayPoint.hidden = Number(matchDisplayPoint.value)<=0
            } else {
               displayPoint.hidden = true
            }
         }
         
      },
      registerAsListener(){
         let requestPoints = []
         if(this.widgets) {
            this.widgets.forEach(widget => {
               if (widget.reportName && widget.reportName.length>0) {
                  requestPoints.push(widget.reportName)
               }
            })
         }
         
         requestPoints = [...new Set(requestPoints)]
         if (requestPoints.length>0) {
            // console.log('registerIsListener')
            // console.log(`asktoken ${this.askToken}`)
            // console.log(`requestPoints ${requestPoints}`)
            this.requestPoints = requestPoints
            this.$socket.emit('registerIsListener', {
               key: this.askToken,
               pointNames: requestPoints
            })
         }
      },
      unregisterAsListener(){
         this.$socket.emit('unregisterIsListener', {
            key: this.askToken
         })
      },
      requestIOdata() {
         // console.log(`requestIOdata`)
         // console.log(this.widgets)
         if(!this.requestPoints || this.requestPoints.length<=0) {
            let requestPoints = []
            if(this.widgets) {
               this.widgets.forEach(widget => {
                  // console.log(widget)
                  if (widget.reportName && widget.reportName.length>0) {
                     requestPoints.push(widget.reportName)
                  }
               })
            }
            
            requestPoints = [...new Set(requestPoints)]
            this.requestPoints = requestPoints
         }
         // console.log(this.requestPoints)
         if (this.requestPoints.length>0) {
            this.timeRequestData = new Date()
            this.$socket.emit('getLastValuePoints', {
              askToken: this.askToken,
              points: this.requestPoints
            })
         }
      },
   },
   created() {
      this.askToken = window.performance.now()
      this.$nextTick(() => {
         if(1==1 || this.FETCH_ON_START){
               this.requestIOdata()
         }
         let self = this
         setTimeout(function(){
            self.registerAsListener()
         }, 200)
      })
      // -------------------------------------------
      this.timer1s = setInterval(() => {
      this.counter++;
      if(this.FETCH_IF_TIMEOUT && this.counter%10==0){
            if(!this.lastTimeGotData || (new Date()-this.lastTimeGotData)>=10000){
               this.requestIOdata()
            }
         }
      }, 1000)
   },
   destroyed() {
      this.unregisterAsListener()
      if (this.timer1s) clearInterval(this.timer1s)
   }
}

export default listener