let setter = {
   methods: {
      showSetter(item, options) {
         if(item && item.reportName.length>0 && !item.readOnly){
            let sendItem = {
               reportName: item.reportName,
               value: item.value,
               unit: item.unit,
            }
            if (item['mode']) {
               sendItem.mode = item.mode
            }
            if(item['sourceControl']){
               sendItem.reportName = item.sourceControl
            }
            if (item['sourceMode'] && item['sourceMode'].length>0) {
               sendItem.sourceMode = item.sourceMode
            }
            if(options) {
               sendItem.options = options
            }
            this.$eventBus.$emit("showPointSetter", sendItem)
         }
      },
      setPoint(point, value) {
         let self = this
         let payload = {
                     points: [{
                        reportName: point.source,
                        value: value
                     }
                     ]}
         
         // let actionDesc = "" + (value?"Turn on ":"Turn off ")
         // actionDesc += (point.subDev?point.subDev:"")
         console.log(`dispatch("isAllowControl")`)
         self.$store.dispatch("isAllowControl").then(() => {
            self.axios.post(self.$root.apiGate + 'setPoints', payload).then(res => {
               if(res.data.status == "OK") {
                  // console.log(res.data.message)
               } else {
               alert(res.data.message)
               }
            }).catch(err => {
                  alert(err)
            })
         }).catch(() => {
            alert("Lỗi! bạn chưa được cấp quyền điều khiển.")
         })
      }
   },
}

export default setter